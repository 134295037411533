define("sfmc-widget/components/full-screen-modal", ["exports", "fluid/components/full-screen-modal/component", "sfmc-widget/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    containerElementSelector: Ember.get(_environment.default, 'APP.rootElement') || 'body'
  });

  _exports.default = _default;
});