define("sfmc-widget/callback/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),

    model() {
      return this.auth.callback();
    },

    afterModel() {
      const url = this.get('auth.redirectTo');

      if (url) {
        this.transitionTo(url);
      } else {
        this.transitionTo('campaigns.index');
      }
    }

  });

  _exports.default = _default;
});