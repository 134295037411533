define("sfmc-widget/mixins/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),

    beforeModel(transition) {
      this._super(...arguments);

      const auth = this.auth;

      if (!auth.authenticated) {
        const url = this.urlFromTransition(transition);
        auth.set('redirectTo', url);
        return this.replaceWith('auth');
      }
    },

    urlFromTransition(transition) {
      let url; // if the transition contains the url we're going to, always use it.
      // if we run into issues with this redirecting us to places we didn't intend to go
      // we should document the reason for it here, so this approach has lower priority.

      if (transition.intent.url) {
        url = transition.url;
      } else if (transition.intent.contexts && transition.intent.contexts.length) {
        url = this.router.urlFor(transition.targetName, transition.intent.contexts[0]);
      } else {
        url = this.router.urlFor(transition.targetName);
      }

      return url;
    }

  });

  _exports.default = _default;
});