define("sfmc-widget/adapters/block", ["exports", "sfmc-widget/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentCompany: Ember.inject.service(),

    urlForFindRecord(id, modelName, snapshot) {
      const baseURL = this.buildURL(modelName, id, snapshot);
      const currentCompanyID = this.get('currentCompany.current.id');
      return `${baseURL}?company_id=${currentCompanyID}`;
    },

    urlForQuery(query, modelName) {
      const baseURL = this.buildURL(modelName);
      const currentCompanyID = this.get('currentCompany.current.id');
      return `${baseURL}?company_id=${currentCompanyID}`;
    }

  });

  _exports.default = _default;
});