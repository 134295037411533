define("sfmc-widget/mirage/factories/campaign", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Campaign ${i}`;
    },

    created() {
      return new Date('March 28, 2018');
    },

    withBlocks: (0, _emberCliMirage.trait)({
      afterCreate(campaign, server) {
        server.createList('block', 3, {
          campaign
        });
      }

    })
  });

  _exports.default = _default;
});