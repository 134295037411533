define("sfmc-widget/blocks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LcEkivh1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"blockedit\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"blockedit__name\"],[8],[1,[24,[\"model\",\"block\",\"name\"]],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"blockedit__title\"],[8],[0,\"Content Settings\"],[9],[0,\"\\n\\n  \"],[5,\"block-style\",[],[[\"@model\"],[[22,\"model\"]]]],[0,\"\\n\\n  \"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"back-button\",\"campaigns.show\",[24,[\"model\",\"block\",\"campaign\",\"id\"]]]],{\"statements\":[[0,\"Back to Block List\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sfmc-widget/blocks/template.hbs"
    }
  });

  _exports.default = _default;
});