define("sfmc-widget/adapters/application", ["exports", "ember-data", "sfmc-widget/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.railsURL,
    namespace: 'api/v2',
    auth: Ember.inject.service(),
    headers: Ember.computed('auth.authenticated', function () {
      const auth = this.auth;
      return {
        Authorization: `Bearer ${auth._token}`,
        'X-Requested-With': 'XMLHttpRequest'
      };
    }),

    shouldReloadAll() {
      return true;
    },

    shouldBackgroundReloadRecord() {
      return false;
    }

  });

  _exports.default = _default;
});