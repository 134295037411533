define("sfmc-widget/mirage/factories/block", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    campaign: (0, _emberCliMirage.association)(),

    name(i) {
      return `Block-${i}`;
    },

    channelType: 'email',

    embedCode(i) {
      return `<img src="www.google.com">${i}</img>`;
    }

  });

  _exports.default = _default;
});