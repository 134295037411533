define("sfmc-widget/services/user-roles", ["exports", "fantastic-beasts/services/user-roles"], function (_exports, _userRoles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _userRoles.default;
    }
  });
});