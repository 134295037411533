define("sfmc-widget/components/company-switcher/row/component", ["exports", "fantastic-beasts/components/company-switcher/row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    currentCompany: Ember.inject.service(),
    isCurrent: Ember.computed('currentCompany.current.id', 'company.id', function () {
      return this.get('currentCompany.current.id') === this.get('company.id');
    }),

    click() {
      if (this.isDisabled) {
        return;
      }

      const company = {
        name: this.company.name,
        id: this.company.id
      };
      this.currentCompany.setCurrentCompany(company);
      this.onSelect(company); // hacky way to close the dialog box, trigger escape on page

      Ember.run(() => {
        const evt = new Event('keydown');
        evt.key = 'esc';
        evt.keyCode = 27;
        document.dispatchEvent(evt);
      });
    }

  });

  _exports.default = _default;
});