define("sfmc-widget/services/fullstory", ["exports", "uatu/services/fullstory", "sfmc-widget/config/environment"], function (_exports, _fullstory, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fullstory.default.extend({
    environment: _environment.default.environment
  });

  _exports.default = _default;
});