define("sfmc-widget/services/raven", ["exports", "uatu/services/raven", "sfmc-widget/config/environment"], function (_exports, _raven, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    environment: _environment.default.environment,
    appName: _environment.default.modulePrefix
  });

  _exports.default = _default;
});