define("sfmc-widget/services/auth", ["exports", "ember-concurrency-decorators", "ember-concurrency", "sfmc-widget/config/environment"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Auth = (_class = (_temp = class Auth extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "clientID", _environment.default.clientID);

      _defineProperty(this, "localStorage", window.localStorage);
    }

    get redirectTo() {
      return this.localStorage.getItem('_movableink_redirect');
    }

    set redirectTo(value) {
      value = value || 'campaigns';
      this.localStorage.setItem('_movableink_redirect', value);
      return value;
    }

    get state() {
      return Math.random().toString(36).slice(2);
    }

    get _token() {
      return this.localStorage.getItem('_token');
    }

    get authenticated() {
      const tokenTime = this.localStorage.getItem('_token_time');
      if (!this._token || !tokenTime) return false;
      const now = new Date().getTime();
      const hour = 1000 * 60 * 60;
      const expired = now - tokenTime > hour;

      if (expired) {
        this.localStorage.removeItem('_token');
        this.localStorage.removeItem('_token_time');
        return false;
      }

      return true;
    }

    getToken() {
      let queryParams = ['response_type=token', `client_id=${this.clientID}`, `state=${this.state}`, `redirect_uri=${_environment.default.appOrigin}/callback`];
      queryParams = queryParams.join('&');
      const url = `${_environment.default.railsURL}/oauth/authorize?${queryParams}`;

      this._open.perform(url);
    }

    get parent() {
      return parent;
    }

    get referrer() {
      return document.referrer;
    }

    redirect(url) {
      window.location.replace(url);
    }

    finish() {
      window.location.href = this.redirectTo || 'campaigns';
    }

    open() {
      this.getToken();
      return true;
    }

    *_open(url) {
      const width = 450,
            height = 730,
            left = screen.width / 2 - width / 2,
            top = screen.height / 2 - height / 2;
      const options = `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${width},height=${height},top=${top},left=${left}`;
      let stillOpen = false;
      const w = window.open(url, 'Movable Ink - Authorize access to your account', options);

      do {
        stillOpen = w && !w.closed;
        yield (0, _emberConcurrency.timeout)(30);
      } while (stillOpen);

      this.finish();
    }

    callback() {
      const url = new URL(this.location());
      const result = url.hash.split('&').reduce(function (result, item) {
        const parts = item.split('=');
        result[parts[0]] = parts[1];
        return result;
      }, {});
      const token = result['#access_token'];
      this.setToken(token);
      this.close();
    }

    close() {
      window.close(); // close auth window
    }

    setToken(token) {
      this.localStorage.setItem('_token', token);
      this.localStorage.setItem('_token_time', new Date().getTime());
    }

    location() {
      return window.location.toString();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "redirectTo", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "redirectTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_open", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_open"), _class.prototype)), _class);
  _exports.default = Auth;
});