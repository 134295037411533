define("sfmc-widget/utils/campaign-code-super-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const grayStyle = "style='color: gray'";
  const grayText = `<span ${grayStyle}>(Move this to the top of the email for each Movable Ink Campaign used. This text will not appear in the final email.)</span>`;

  function _default(name) {
    return `<span style="color: black; padding: 5px; line-height: 35px; font-size: 12px;">${name} Campaign Code ${grayText}</span>`;
  }
});