define("sfmc-widget/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCSAzue5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"app-header\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"app-header__name\"],[8],[0,\"\\n    Error\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"red-box\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"icon inline\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"icon-sfmc-cancel\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"caption inline\"],[8],[0,\"\\n      This block is unavailable. Please \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"link-to-campaigns\",\"campaigns.index\"]],{\"statements\":[[0,\"click here\"]],\"parameters\":[]},null],[0,\"\\n      to return to the campaign list.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sfmc-widget/error/template.hbs"
    }
  });

  _exports.default = _default;
});