define("sfmc-widget/components/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "icMFXB2L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"search-bar\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"slds-icon_container slds-icon-utility-search\"],[10,\"for\",\"search-input\"],[8],[0,\"\\n    \"],[7,\"svg\",true],[10,\"class\",\"slds-icon\"],[8],[0,\"\\n      \"],[7,\"use\",true],[10,\"xlink:href\",\"/assets/svgs/symbols.svg#search\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"input\",true],[11,\"value\",[22,\"currentTerm\"]],[10,\"id\",\"search-input\"],[10,\"class\",\"slds-input\"],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"submitSearch\"],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"currentTerm\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",false],[12,\"class\",\"slds-icon_container slds-icon-utility-clear\"],[3,\"action\",[[23,0,[]],\"clear\"]],[8],[0,\"\\n      \"],[7,\"svg\",true],[10,\"class\",\"slds-icon\"],[8],[0,\"\\n        \"],[7,\"use\",true],[10,\"xlink:href\",\"/assets/svgs/symbols.svg#clear\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sfmc-widget/components/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});