define("sfmc-widget/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    blockSDK: Ember.inject.service(),

    model() {
      return this.blockSDK.getMetaData();
    },

    afterModel(metadata) {
      if (metadata.campaignCode && metadata.campaignCode.id) {
        const campaignId = metadata.campaignCode.id;
        return this.transitionTo('campaigns.show', campaignId);
      } else if (metadata.blockId) {
        const blockId = metadata.blockId;
        return this.transitionTo('blocks', blockId);
      } else {
        return this.replaceWith('campaigns.index');
      }
    }

  });

  _exports.default = _default;
});