define("sfmc-widget/blocks/route", ["exports", "sfmc-widget/mixins/auth"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BlocksRoute = (_class = (_temp = class BlocksRoute extends Ember.Route.extend(_auth.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "blockSDK", _descriptor, this);

      _initializerDefineProperty(this, "mixpanel", _descriptor2, this);

      _initializerDefineProperty(this, "cookies", _descriptor3, this);
    }

    model(params) {
      return Ember.RSVP.hash({
        block: this.store.find('block', params.id),
        metadata: this.blockSDK.getMetaData()
      });
    }

    afterModel(model) {
      var _model$block, _model$block2;

      this.cookies.write('campaignID', model.block.belongsTo('campaign').id());
      this.mixpanel.track('SFMC - Block Selected', {
        block_id: (_model$block = model.block) === null || _model$block === void 0 ? void 0 : _model$block.id
      });
      const metadata = model.metadata;
      const modelId = (_model$block2 = model.block) === null || _model$block2 === void 0 ? void 0 : _model$block2.id;
      const metadataBlockId = metadata.blockId;

      if (modelId != metadataBlockId) {
        var _model$block3;

        const styleData = {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          alignment: 'left'
        };
        metadata['blockId'] = modelId;
        metadata['mIStyleData'] = styleData;
        Ember.set(model, 'metadata', metadata);
        this.blockSDK.sendToEditor((_model$block3 = model.block) === null || _model$block3 === void 0 ? void 0 : _model$block3.embedCode, metadata);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "blockSDK", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mixpanel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cookies", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = BlocksRoute;
});