define("sfmc-widget/components/guide-center/views/feedback-form", ["exports", "fantastic-beasts/components/guide-center/views/feedback-form/component", "sfmc-widget/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _environment.default
  });

  _exports.default = _default;
});