define("sfmc-widget/campaigns/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ipNRaGs",
    "block": "{\"symbols\":[\"campaign\"],\"statements\":[[1,[28,\"search-bar\",null,[[\"value\",\"placeholder\",\"onSubmit\"],[[24,[\"q\"]],[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"submit\"],null]]]],false],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"campaigns-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",false],[12,\"role\",\"button\"],[12,\"class\",\"campaign-item\"],[3,\"action\",[[23,0,[]],\"goToCampaign\",[23,1,[]]]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"campaign-name\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"campaign-created-at\"],[8],[0,\"Created: \"],[1,[23,1,[\"formattedCreatedAt\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[4,\"infinity-loader\",null,[[\"infinityModel\",\"hideOnInfinity\",\"class\"],[[24,[\"model\"]],true,\"slds-is-relative\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"infinity-loading\"],[10,\"style\",\"height: 6rem;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"role\",\"status\"],[10,\"class\",\"slds-spinner slds-spinner_x-small\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"slds-assistive-text\"],[8],[0,\"Loading\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"slds-spinner__dot-a\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"slds-spinner__dot-b\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sfmc-widget/campaigns/index/template.hbs"
    }
  });

  _exports.default = _default;
});