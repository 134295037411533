define("sfmc-widget/campaigns/route", ["exports", "sfmc-widget/mixins/auth"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CampaignsRoute = (_class = (_temp = class CampaignsRoute extends Ember.Route.extend(_auth.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "mixpanel", _descriptor2, this);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);

      _initializerDefineProperty(this, "currentCompany", _descriptor4, this);
    }

    async beforeModel() {
      super.beforeModel(...arguments);
      if (!this.auth.authenticated) return; // auth mixin's beforeModel doesn't actually end this transition, we don't want the rest of this hook to run

      const user = await this._setupUser();

      const currentCompany = this._setupCompany(user);

      this._stubMixpanel(user, currentCompany);
    }

    async _setupUser() {
      if (this._user) {
        return this._user;
      }

      const user = await this.currentUser.fetch();
      this._user = user;
      return user;
    }

    _setupCompany(user) {
      const company = user.company;

      if (!this.get('currentCompany.current')) {
        this.currentCompany.setCurrentCompany(company);
      }

      return company;
    }

    _stubMixpanel(user, company) {
      var _company$current;

      const mixpanelUser = user.getProperties('id', 'email');
      mixpanelUser['company'] = company === null || company === void 0 ? void 0 : (_company$current = company.current) === null || _company$current === void 0 ? void 0 : _company$current.name;
      this.mixpanel.install(mixpanelUser);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mixpanel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentCompany", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = CampaignsRoute;
});