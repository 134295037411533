define("sfmc-widget/services/block-sdk", ["exports", "blockSDK", "sfmc-widget/utils/campaign-code-super-content"], function (_exports, _blockSDK, _campaignCodeSuperContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BlockSDK extends Ember.Service {
    sendToEditor(content, metadata) {
      const sdk = this.getSDK();
      let styleOptions;
      let superContent;

      if (metadata && metadata.mIStyleData) {
        styleOptions = this._transformStyleData(metadata.mIStyleData);
        delete metadata.campaignCode;
        sdk.setData(metadata);
      }

      try {
        superContent = this._wrapEmbedCode(this._cleanContent(content), styleOptions);
      } catch (e) {
        throw new Error(`Invalid HTML: ${content}`);
      }

      sdk.setSuperContent(superContent, function (confirmation) {
        return confirmation;
      });

      const wrappedContent = this._wrapEmbedCode(content, styleOptions);

      sdk.setContent(wrappedContent, function (confirmation) {
        return confirmation;
      });
    }

    sendCampaignCode(campaign) {
      const sdk = this.getSDK();
      const {
        id,
        name,
        campaignCode
      } = campaign;
      const superContent = (0, _campaignCodeSuperContent.default)(name);
      sdk.setSuperContent(superContent, function (confirmation) {
        return confirmation;
      });
      sdk.setContent(campaignCode, function (confirmation) {
        return confirmation;
      });
      sdk.setData({
        campaignCode: {
          id,
          name
        }
      });
    }

    getMetaData() {
      const sdk = this.getSDK();
      return new Promise(resolve => {
        sdk.getData(function (data) {
          resolve(data);
        });
      });
    }

    _transformStyleData(styleData) {
      return {
        style: `padding: ${styleData.paddingTop}px ${styleData.paddingRight}px ${styleData.paddingBottom}px ${styleData.paddingLeft}px`,
        align: styleData.alignment
      };
    }

    _cleanContent(content) {
      const parser = new DOMParser();
      const document = parser.parseFromString(content, 'text/html');
      const imgTag = document.querySelector('img');
      const aTag = document.querySelector('a');
      let tagToReturn = imgTag;

      if (aTag) {
        aTag.href = this._encodeURLQueryParams(aTag.href);
        tagToReturn = aTag;
      }

      imgTag.src = this._encodeURLQueryParams(imgTag.src);
      return tagToReturn.outerHTML;
    }

    _encodeURLQueryParams(decodedURL) {
      const url = new URL(decodedURL);
      const search = url.search;
      if (!search) return decodedURL;
      const encodedParams = encodeURI(search);
      return url.origin + url.pathname + encodedParams;
    }

    _wrapEmbedCode(content, styleOptions = {
      align: 'left',
      style: ''
    }) {
      const parser = new DOMParser();
      const contentDocument = parser.parseFromString(content, 'text/html');
      const imgTag = contentDocument.querySelector('img');
      const aTag = contentDocument.querySelector('a');
      let targetTag = imgTag;

      if (aTag) {
        targetTag = aTag;
      }

      const tdTag = document.createElement('td');
      tdTag.appendChild(targetTag);
      tdTag.setAttribute('align', styleOptions.align);
      tdTag.setAttribute('style', styleOptions.style);
      const trTag = document.createElement('tr');
      trTag.appendChild(tdTag);
      const tbodyTag = document.createElement('tbody');
      tbodyTag.appendChild(trTag);
      const tableTag = document.createElement('table');
      tableTag.setAttribute('width', '100%');
      tableTag.setAttribute('cellspacing', 0);
      tableTag.setAttribute('cellpadding', 0);
      tableTag.appendChild(tbodyTag);
      return tableTag.outerHTML;
    }

    getSDK() {
      return new _blockSDK.default();
    }

  }

  _exports.default = BlockSDK;
});