define("sfmc-widget/campaigns/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexController = (_dec = Ember.computed('currentCompany.current.name'), (_class = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "mixpanel", _descriptor, this);

      _initializerDefineProperty(this, "currentCompany", _descriptor2, this);

      _defineProperty(this, "queryParams", ['q']);
    }

    get placeholder() {
      var _this$currentCompany, _this$currentCompany$;

      return `Search Campaigns in ${(_this$currentCompany = this.currentCompany) === null || _this$currentCompany === void 0 ? void 0 : (_this$currentCompany$ = _this$currentCompany.current) === null || _this$currentCompany$ === void 0 ? void 0 : _this$currentCompany$.name}`;
    }

    goToCampaign(campaign) {
      this.mixpanel.track('SFMC - Campaign Selected', {
        campaign_id: campaign.id
      });
      return this.transitionToRoute('campaigns.show', campaign.id);
    }

    submit(q) {
      return this.transitionToRoute({
        queryParams: {
          q
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mixpanel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentCompany", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "placeholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToCampaign", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "goToCampaign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = IndexController;
});