define("sfmc-widget/components/block-style/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function normalizeValue(value) {
    if (value > 99) return 99;else if (!value) return 0;else return value;
  }

  const normalized = function (dependentKey) {
    return Ember.computed(dependentKey, {
      get() {
        return normalizeValue(this.get(dependentKey));
      },

      set(key, value) {
        this.set(dependentKey, value);
        return value;
      }

    });
  };

  let BlockStyle = (_dec = (0, _component.classNames)('block-editor'), _dec2 = Ember.computed.reads('model.metadata.mIStyleData'), _dec3 = normalized('styleData.paddingTop'), _dec4 = normalized('styleData.paddingRight'), _dec5 = normalized('styleData.paddingBottom'), _dec6 = normalized('styleData.paddingLeft'), _dec7 = Ember.computed.alias('styleData.alignment'), _dec(_class = (_class2 = (_temp = class BlockStyle extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "blockSDK", _descriptor, this);

      _initializerDefineProperty(this, "styleData", _descriptor2, this);

      _initializerDefineProperty(this, "paddingTop", _descriptor3, this);

      _initializerDefineProperty(this, "paddingRight", _descriptor4, this);

      _initializerDefineProperty(this, "paddingBottom", _descriptor5, this);

      _initializerDefineProperty(this, "paddingLeft", _descriptor6, this);

      _initializerDefineProperty(this, "alignment", _descriptor7, this);
    }

    modifyStyle() {
      let styleData = {
        paddingTop: this.paddingTop,
        paddingRight: this.paddingRight,
        paddingBottom: this.paddingBottom,
        paddingLeft: this.paddingLeft,
        alignment: this.alignment
      };

      for (let key in styleData) {
        if (key.includes('padding')) {
          let value = normalizeValue(styleData[key]);
          this.set(key, value);
          styleData[key] = value;
        }
      }

      const metadata = {
        mIStyleData: styleData,
        blockId: this.get('model.block.id')
      };
      this.blockSDK.sendToEditor(this.get('model.block.embedCode'), metadata);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "blockSDK", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "styleData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "paddingTop", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "paddingRight", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "paddingBottom", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "paddingLeft", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "alignment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "modifyStyle", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "modifyStyle"), _class2.prototype)), _class2)) || _class);
  _exports.default = BlockStyle;
});