define("sfmc-widget/services/mixpanel", ["exports", "uatu/services/mixpanel", "sfmc-widget/config/environment"], function (_exports, _mixpanel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixpanel.default.extend({
    init(...args) {
      this._super(...args);

      this.debug = this.debug ? this.debug : Ember.getWithDefault(_environment.default, 'mixpanel.debug', false);
      this.apiToken = this.apiToken ? this.apiToken : Ember.getWithDefault(_environment.default, 'mixpanel.token', null);
    }

  });

  _exports.default = _default;
});