define("sfmc-widget/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /* eslint-disable ember/no-get */
  function _default() {
    this.namespace = 'api/v2';
    this.passthrough('/_percy/**');
    this.get('/campaigns');
    this.get('/campaigns/:id');
    this.get('/blocks', function (schema, request) {
      const channelType = request.queryParams.channel_type;

      if (channelType) {
        return schema.blocks.where({
          channelType
        });
      } else {
        return schema.blocks;
      }
    });
    this.get('/blocks/:id');
    this.get('/users', function (schema, request) {
      if (request.queryParams.id === 'me') {
        return schema.users.first();
      }
    });
    this.get('/companies/available', function () {
      return {
        companies: [{
          children: [],
          disabled: false,
          id: 1,
          name: 'Movable Pink'
        }, {
          children: [],
          disabled: false,
          id: 2,
          name: 'UFC'
        }]
      };
    });
  }
});