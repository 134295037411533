define("sfmc-widget/services/current-user", ["exports", "fantastic-beasts/services/current-user"], function (_exports, _currentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _currentUser.default;
    }
  });
});