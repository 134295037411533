define("sfmc-widget/router", ["exports", "sfmc-widget/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.routerRootURL
  });
  Router.map(function () {
    this.route('auth');
    this.route('callback');
    this.route('campaigns', function () {
      this.route('show', {
        path: '/:id'
      });
    });
    this.route('blocks', {
      path: '/blocks/:id'
    });
  });
  var _default = Router;
  _exports.default = _default;
});