define("sfmc-widget/campaigns/index/route", ["exports", "sfmc-widget/mixins/auth"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CampaignsIndexRoute = (_class = (_temp = class CampaignsIndexRoute extends Ember.Route.extend(_auth.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "cookies", _descriptor, this);

      _initializerDefineProperty(this, "infinity", _descriptor2, this);

      _initializerDefineProperty(this, "currentCompany", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        q: {
          refreshModel: true,
          replace: true
        },
        company_id: {
          refreshModel: true,
          replace: true
        }
      });
    }

    async beforeModel() {
      super.beforeModel(...arguments);
      const cookies = this.cookies;
      const campaignID = cookies.read('campaignID');

      if (campaignID) {
        cookies.clear('campaignID');
        return this.transitionTo('campaigns.show', campaignID);
      }
    }

    model(params) {
      var _this$currentCompany, _this$currentCompany$;

      const pageParams = {
        perPage: 8,
        startingPage: 1
      };
      params.company_id = (_this$currentCompany = this.currentCompany) === null || _this$currentCompany === void 0 ? void 0 : (_this$currentCompany$ = _this$currentCompany.current) === null || _this$currentCompany$ === void 0 ? void 0 : _this$currentCompany$.id;
      return this.infinity.model('campaign', Object.assign(pageParams, params));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cookies", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "infinity", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentCompany", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = CampaignsIndexRoute;
});