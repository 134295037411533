define("sfmc-widget/mixins/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    header: Ember.inject.service(),

    afterModel(model) {
      this._super(...arguments);

      this.set('header.model', model);
    }

  });

  _exports.default = _default;
});