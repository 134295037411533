define("sfmc-widget/services/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Header = (_dec = Ember.computed('router.currentRouteName', 'model.campaign.name'), (_class = (_temp = class Header extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "model", null);
    }

    get name() {
      const routeName = this.router.currentRouteName;
      let headerName;

      if (routeName.startsWith('campaigns.index')) {
        headerName = 'Campaigns';
      } else if (routeName === 'campaigns.show') {
        var _this$model, _this$model$campaign;

        headerName = ((_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$campaign = _this$model.campaign) === null || _this$model$campaign === void 0 ? void 0 : _this$model$campaign.name) || 'Campaign';
      } else {
        headerName = 'Loading...'; // who knows when this will get hit
      }

      return headerName;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "name", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "name"), _class.prototype)), _class));
  _exports.default = Header;
});